import React from 'react';
import "./TableItem.css";
//import SadOutline from "../../sad-outline.svg";
//import HappyOutline from "../../happy-outline.svg";
import Happy from "../../happy.svg";
// import HappyWhite from "../../happy-white.svg";
// import Expire from "../Expire/Expire";

const roomItem = (props) => {

    const {item, vip} = props;

    const predictItems = {
        "* คู่":"รูด คู่หลัง",
        "คู่ *" : "รูด คู่หน้า",
        "* สูง" : "รูด สูงหลัง",
        "สูง *" : "รูด สูงหน้า",
        "* คี่" : "รูด คี่หลัง",
        "คี่ *" : "รูด คี่หน้า",
        "* ต่ำ" : "รูด ต่ำหลัง",
        "ต่ำ *" : "รูด ต่ำหน้า",
    };

    //console.log("Game ->", item.game.key);

    let predictHistories = null;
    let preCssClass = item.predict.replace("* ", "-");
    preCssClass = preCssClass.replace(" *", "-");
    let predictClass = "predict " + preCssClass;
    let predictWordingCssClass = "predict-wording " + preCssClass;
    const predict = item.predict !== undefined ? item.predict.toUpperCase() : "";
    const predictWordingItem = predictItems[item.predict];
    let predictWordingText = null;
    //console.log("predictWording ->",predictWordingItem);

    if( predictWordingItem != null || predictWordingItem != undefined ) {
        predictWordingText = predictWordingItem;
    }

    if( item.is_win !== undefined && item.is_win) {
        predictClass += " win";
    }

    if( vip === false && item.predict_histories !== undefined ) {
    // if(  item.predict_histories !== undefined ) {
        let sad = 0;

        predictHistories = item.predict_histories.map( (history, i) => {

            if( history === 1 ) {
                sad = 0;
            } else {
                sad ++;
            }

            if( history === 1 ) {
                return <li key={i}><img className="predict-icon" src={Happy} alt="Happy" /></li>
            } else {
                //return <li key={i}><span className="prdict-icon sad">{sad}</span></li>
                return <li key={i}><span className="prdict-icon sad">&nbsp;</span></li>
            }
        });
    }

    //console.log(item.point);

    let point = 0;
    if(  vip === false ) {
        point = item.countpredix > 0 ? Math.floor(100 * item.enjoy / item.countpredix) : 0;
    } else {
        point = item.point !== undefined ? item.point : 0;
    }

    let cardCssClass = "card";
    let gameNoCssClass = "game-no blinking";
    if( ! item.can_play ) {
        cardCssClass += " can-not-play"
        gameNoCssClass = "game-no";
    } else if( item.can_play && item.no_green_and_blue ) {
        cardCssClass += " no-green-and-blue"
    }

    if( item.new_game ) {
        cardCssClass += " new-game";
    }

    let pointCssClass = "point";
    if( vip === false && point >= 65 ) {
        pointCssClass += " bg";
    }

    let followCssClass = "game-follow-no";
    if(  vip === false && item.game_follow_no <= 3 && ! item.no_green_and_blue && item.countpredix >= 10) {
        followCssClass += " bg";
    }

    let follow = "&nbsp;";
    if( vip === false && item.game_follow_no > 0 ) {
        follow = "ทบไม้ที่ : "+item.game_follow_no;
    }

    
    let checkWithPHP = "#";
    let target = null;
    //let phpText = "&nbsp;";
    if( item.check_with_php !== "" ) {
        checkWithPHP = "https://www.bombermanok.com/bacvip.php?history=" + item.check_with_php +"&mode=" + item.tumra;
        target = "_blank";
        //phpText = "PHP";
    }

    let followHappy = null;
    if( item.lastEnjoy ) {
        //followHappy = <a href={checkWithPHP} target={target} rel="noopener noreferrer"><img className="predict-icon white" src={HappyWhite} alt="Happy" /></a>;
        const aId = "a-" + item.id;
        followHappy = <a href={checkWithPHP} id={aId} target={target} rel="noopener noreferrer">$$$$$</a>;
        // followHappy = <Expire delay={4000} link={checkWithPHP} target={target}>$$$$$ {new Date().getTime()}</Expire>;
        /*const xTimeout =  setTimeout(function(){
            //console.log(aId, xTimeout);
            let tagA = document.getElementById(aId);
            if( tagA != null ) {
                tagA.innerHTML = "&nbsp;";
            }
            
            if( xTimeout !== null || xTimeout !== undefined ) {
                clearTimeout(xTimeout);
            }

        }, 4000);
        */
        //console.log(followHappy);

    } 

    const linkFollow = followHappy !== null ? followHappy : <a href={checkWithPHP} target={target} rel="noopener noreferrer" dangerouslySetInnerHTML={{__html: follow}} />
    let linkToWeb = "#";
    if( props.type === "tb") {
        linkToWeb =  "https://truthbet.com/g/live/baccarat/" + item.id;
    } else if( props.type === "poipet") {
        linkToWeb =  "https://poipet.com/games/live/baccarat/" + item.id;
    } else if( props.type === "tbtiger") {
        linkToWeb =  "https://truthbet.com/g/live/dragon_tiger/" + item.id;
    } else if( props.type === "tbhilohitech") {
        linkToWeb =  "https://truthbet.com/g/live/hilo_hitech/" + item.id;
    } else if( props.type === "tbhilolocal") {
        linkToWeb =  "https://truthbet.com/g/live/hilo_local/" + item.id;
    } else if( props.type === "tbkino") {
        linkToWeb =  "https://truthbet.com/g/live/kino/" + item.id;
    }

    const linkToWebTarget = (props.type.indexOf("tb") !== -1 || props.type === "poipet") ? "_blank" : null;

    const tablePrfix = (props.type.indexOf("tb") !== -1 || props.type === "poipet") ? "โต๊ะ" : null;

    let gameTypeTitle = null;
    /*if( item.game.key.indexOf("bacarat") === -1) {
        let gameTypeTitleName = "";
        if( item.game.key === "keno" ) {
            gameTypeTitleName = "คีโน่";
        } else if( item.game.key === "keno_classic" ) {
            gameTypeTitleName = "คีโน่ คลาสสิค";
        } else if( item.game.key === "hilo_hitech" ) {
            gameTypeTitleName = "ไฮโล ไฮเทค";
        } else if( item.game.key === "hilo_local" ) {
            gameTypeTitleName = "ไฮโล พื้นบ้าน";
        } else if( item.game.key === "dragon_tiger" ) {
            gameTypeTitleName = "เสือมังกร";
        }

        gameTypeTitle = <h3 className="game-type-title">{gameTypeTitleName}</h3>;
    }*/

    const smallResults = item.result_predict.filter( item => {
        const checkItem = parseInt(item);
        return checkItem <= 50;
    });

    const bigResults = item.result_predict.filter( item => {
        const checkItem = parseInt(item);
        return checkItem > 50;
    });;

    //console.log("smallResults ->", smallResults);
    //console.log("bigResults ->", bigResults);

    let resultPredict = null;

    if( item.result_predict ) {
        //const resultSmallItem = smallResults.map( item => <span key={item} className="number-item">{item}</span>);
        //const resultBigItem = bigResults.map( item => <span key={item} className="number-item">{item}</span>);

        let colorIndex = 0;
        let checkNum = "";
        const resultPredictItems = item.result_predict.map( item => {
            const firstNum = item.substring(0, 1);
            if( checkNum != firstNum ) {
                ++colorIndex;
                checkNum = firstNum;
            }
            const numberItemCssClass = "number-item num-style-" + colorIndex;
            return <span key={item} className={numberItemCssClass}>{item}</span>
        });
        resultPredict = <div className="numbers">
            {resultPredictItems}
        </div>;
        /*resultPredict = <div className="row">
            <div className="col-md-6">
                <h3 className="result-title">เล็ก</h3>
                <div className="numbers">
                    {resultSmallItem}
                </div>
            </div>
            <div className="col-md-6">
                <h3 className="result-title">ใหญ่</h3>
                <div className="numbers">
                    {resultBigItem}
                </div>
            </div>
        </div>*/

    }

    let cardTitle = "ห้องที่ "+ item.id +" ";
    let aItem = item.title.split(" (");
    cardTitle += aItem[aItem.length-1] == "2 ตัวบน)" ? "สองตัว-บน" : "สองตัว-ล่าง";
    //console.log(aItem);

    return (
        <div className="col-md-3" style={{paddingLeft:'5px', paddingRight: '5px'}}>
            <div className={cardCssClass}>
                <div className="card-body">
                    { gameTypeTitle }
                    <h2 className="card-title">
                        <a href={linkToWeb} target={linkToWebTarget} rel="noopener noreferrer">{cardTitle}</a> <br />
                        รอบที่ <span className={gameNoCssClass}>({item.game_no})</span> { false ? "ตำรา "+item.tumra : null }</h2>
                    { false ? null : <p className={pointCssClass}>{ vip === false ? "ความแม่น "+point+" point" : "โอกาส "+point+" %" }</p> }
                    {  vip === false ? <p className={followCssClass}>{linkFollow}</p> : null }
                    <p className={predictClass}><span>{predict}</span><span className={predictWordingCssClass}>{predictWordingText}</span></p>
                    { resultPredict }
                    { vip === false ? <div className="predict-history">
                        <ul>
                            {predictHistories}
                        </ul>
                    </div> : null }



                </div>
            </div>
        </div>
    )
}

export default roomItem;