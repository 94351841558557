import React from 'react';
import Table from './components/Table/Table';
import './App.css';
import siteLogo from './logo.png';


function App() {

  const type = "tb"; /* tb, tbtiger, tbhilohitech, tbhilolocal, tbkino, tbhkt, sa, se */
  let logo = siteLogo;
  let registerLink = "https://dog-lotto.com/affiliate/LehEsRBS_L";
  let registerText = "DOGLOTTO AI";
  let linkTitle = "DOGLOTTO AI โปรแกรมสูตร ยี่กีเฮียหมา AI สูตรฟรีที่ใช้หาเงินได้จริง";

  return (
      <div className="main">
        <div className="container">
          <div className="logo-box">
            <a href="https://www.doglotto-ai.com/" target="_blank" rel="noopener noreferrer" title={linkTitle}><img className="img-logo" src={logo} alt={linkTitle} /></a>
          </div>
          <div className="mypoint">
            <h2>DOGLOTTO AI โปรแกรมสูตร ยี่กีเฮียหมา AI สูตรฟรีที่ใช้หาเงินได้จริง</h2>
            <h2>สอนใช้สูตรฟรี ทางไลน์ คลิ๊ก &gt;&gt;&gt; <a href="https://lin.ee/d5ISASl" target="_blank" title={linkTitle}  rel="noopener noreferrer" className="line">@doglottoai</a> (มี @ ด้วย)</h2>
            <h2 className="red"> แนะนำเว็บที่ใช้ได้ดีกับสูตร DOGLOTTO AI มีเครดิตฟรีเยอะๆ</h2>
            <h2><a href={registerLink} target="_blank" title="สมัคร DOG LOTTO" rel="noopener noreferrer">สมัคร DOG LOTTO</a> (ฟรีเครดิต 300 แทงฮานอยทุกเดือน)</h2>
          </div>

        </div>

              <Table />

      </div>
  );
}

export default App;

