import React, { Component } from 'react';
import openSocket from 'socket.io-client';
import TableItem from './TableItem';
import './Table.css';
import queryString from 'query-string';

class Table extends Component {

    state = {
        online : 0,
        // loggin_url : 'http://melabadmin.me/token?r=',
        loggin_url : 'https://melap101.com/freeapp/public/token?r=',
        //loggin_url : 'https://www.bombermanok.com/loginvip?r=',
        type : 'yeekee', /* */
        vip : false,
        debug : false,
        // endpoint : "http://localhost:3003",
        endpoint : "https://doglotto-ai.com",
        /*data : [
            {
                id: 0,
                title: '2 ตัวบน',
                game_id: 0,
                game_no: 0,
                tumra: 1,
                can_play: true,
                point: 50,
                game_follow_no: 3,
                predict: 'b >>',
                result_predict : [10,20,30,40,50,60,70,80,90],
                predict_histories: [1, 0, 1, 0, 0, 1, 1, 0, 0, 1, 1, 1, 0, 1, 0, 0, 0]
            },
            {
                id: 1,
                title: '2 ตัวล่าง',
                game_id: 1,
                tumra: 1,
                url: 'games.baccarat.show',
                game_no: 0,
                can_play: true,
                point: 0,
                game_follow_no: 0,
                predict: 'P <<',
                result_predict : ["01","03","05","07","09",11,13,15,17,19,21,23,25,27,29],
                predict_histories: [0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1]
            }
          ],
         */
        data : null
    }

    componentDidMount = () => {
        const {endpoint, type, loggin_url, vip, debug} = this.state;

        const params = queryString.parse(window.location.search);
        //console.log(window.location.search);
        //console.log(params);

        const typeNoCheckToken = ["tbtiger", "tbhilohitech", "tbhilolocal", "tbkino", "tbhkt"];

        const token = params.token !== undefined ? params.token : "";
        const r = type;
        if( token === "" ) {
            //if( ! vip && ! typeNoCheckToken.includes(type) ) {
                //window.location.replace(loggin_url + r);
                //return;
            //}
        }

        let socket = openSocket(endpoint, {
            query : 'r='+r+'&vip='+vip+'&token=' + token + '&debug=' + debug,
            path : '/socket.io',
            rejectUnauthorized: false
        });

        socket.on("posts", data => {
           //console.log("post -->", data);

            this.setState(prevState => {
                return {
                    data : data
                }
            });

        });

        socket.on("auth", data => {
            //console.log("auth -> ", data);
            if( data === "invalid" ) {
                //if( ! vip && ! typeNoCheckToken.includes(type)) {
                    //window.location.replace(loggin_url + r);
                //}
            }
        });

        socket.on("disconnect", data => {
            //console.log("disconnect -> ", data);
            //if( data === "invalid" ) {
                if( ! vip ) {
                 //   window.location.replace(loggin_url + r);
                }
            //}
        });

        socket.on("me", data => {
            //console.log("me -> ", data);
        });

        socket.on("logged_in", function(name){
           //console.log("Welcome back " + name + ", nice to see you again!");
        });
          
        /*socket.on("invalid", function(){
            console.log("Username / Password Invalid, Please try again!");
        });
        */

        socket.on("invalid", data => {

            //console.log(loggin_url);
            //console.log(data);

            this.setState(prevState => {
                return {
                    data : null
                }
            });

            
        });
          

        socket.on("error", (data) => {
            //console.log("error", data);

            this.setState(prevState => {
                return {
                    data : null
                }
            });

           // window.location.replace(loggin_url + r);
        });

        /*socket.on("bacara_table", data => {
            //console.log("bacara_table", data);

            this.setState(prevState => {
                return {
                    data : data
                }
            });
        });*/

        socket.on("online_total", data => {
            //console.log("online_total ", data);
 
             this.setState(prevState => {
                 return {
                     online : data
                 }
             });
 
         });
        
    }

    onClickHandler = () => {
        //console.log('[onClickHandler] is calling...');
        const {endpoint} = this.state;
        const socket = openSocket(endpoint, {
            query: 'token=token-xxxxbb'
        });
        socket.emit('post-login', {
            user: "user",
            pass: "pass"
        });
    }

    render() {

        let rooms = <p className="loading">Loading....</p>
        const {data, type, vip} = this.state;
        const displayItemCount = 15;

        if( data != null && data.length > 0  ) {
            const topItems = data.filter( item => item.game_cate == "top" );
            const bottomItems = data.filter( item => item.game_cate == "bottom" );

            const topTableItem = topItems.map( item => <TableItem key={item.id} item={item} type={type} vip={vip}  />  );
            const bottomTableItem = bottomItems.map( item => <TableItem key={item.id} item={item} type={type} vip={vip}  />  );

            const topResult = data[0].results;
            let topResultText = "";

            if( topResult != null ) {
                let topResultCount = topResult.length - 1;
                let loopStart = 0;
                if( topResultCount > displayItemCount) {
                    loopStart = topResultCount - displayItemCount;
                }

                for(let i = loopStart; i <= topResultCount; i++) {
                    if( topResultText != "") {
                        topResultText += ", "
                    }

                    topResultText += topResult[i];
                }

            }


            const bottomResult = data[4].results;
            let bottomResultText = "";
            if( bottomResult != null ) {
                let bottomResultCount = bottomResult.length - 1;
                let loopStart = 0;
                if( bottomResultCount > displayItemCount) {
                    loopStart = bottomResultCount - displayItemCount;
                }

                for(let i = loopStart; i <= bottomResultCount; i++) {
                    if( bottomResultText != "") {
                        bottomResultText += ", "
                    }

                    bottomResultText += bottomResult[i];
                }

            }

            let resetMessage = data[0].resetDataIn != "" ? <p className="reset-message blinking2">{data[0].resetDataIn}</p> : null;

            rooms = <>
                {resetMessage}
                <h2 className="room-title">2 ตัวบน <span className="latest-result">ผล {displayItemCount} รอบล่าสุด {topResultText}</span></h2>
                <div className="row row-top">
                    {topTableItem}
                </div>
                <h2 className="room-title">2 ตัวล่าง <span className="latest-result">ผล {displayItemCount} รอบล่าสุด {bottomResultText}</span></h2>
                <div className="row row-bottom">
                    {bottomTableItem}
                </div>
            </>



        }
        
        return (
            <>
                <div className="container">
                    <h5 className="text-center">Online : {this.state.online}</h5>
                </div>

                {rooms}

            </>
        );
    }
}

export default Table;